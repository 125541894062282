define("discourse/plugins/discourse-shared-edits/pre-initializers/extend-composer-service", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/plugins/discourse-shared-edits/lib/shared-edits"], function (_exports, _pluginApi, _decorators, _sharedEdits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const SHARED_EDIT_ACTION = "sharedEdit";
  const PLUGIN_ID = "discourse-shared-edits";
  var _default = _exports.default = {
    name: "discourse-shared-edits-composer-service",
    initialize: container => {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.shared_edits_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.6", api => {
        var _dec, _dec2, _obj;
        api.modifyClass("service:composer", (_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.observes)("model.reply"), (_obj = {
          pluginId: PLUGIN_ID,
          async open(opts) {
            await this._super(opts);
            if (opts.action === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.setupSharedEdit)(this.model);
            }
          },
          collapse() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              return this.close();
            }
            return this._super();
          },
          close() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.teardownSharedEdit)(this.model);
            }
            return this._super();
          },
          save() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              return this.close();
            }
            return this._super.apply(this, arguments);
          },
          _listenForClose() {
            this.appEvents.on("composer:close", () => this.close());
          },
          _handleSharedEdit() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.performSharedEdit)(this.model);
            }
          },
          _saveDraft() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              return;
            }
            return this._super();
          }
        }, (_applyDecoratedDescriptor(_obj, "_listenForClose", [_dec], Object.getOwnPropertyDescriptor(_obj, "_listenForClose"), _obj), _applyDecoratedDescriptor(_obj, "_handleSharedEdit", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_handleSharedEdit"), _obj)), _obj)));
      });
    }
  };
});